@media all and (min-width: 480px) {
    .Login {
        -ms-flex-align: center;
            align-items: center;
        display: -ms-flexbox;
        display: flex;
        height: 100vh;
    }

    .Login form {
        border: 1px solid #f9f8f8;
        -webkit-box-shadow: 0 0 10px #cfcfcf;
                box-shadow: 0 0 10px #cfcfcf;
        padding: 15px;
        margin: 0 auto;
        max-width: 320px;
    }
}
