@media print {
    * {
        font-size: 14px !important;
    }

    h1, h2, h3, h4 {
        padding: 0;
        margin: 5px 0;
    }

    .no-print, .no-print * {
        display: none !important;
    }
}

.absolute {
    position: fixed;
}

.fix-top {
    top: 0;
    left: 0;
}

.wide {
    width: 100vw;
}

.super-z {
    z-index: 10000;
}

.center {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
}

.form-label {
    text-align: left;
    width: 150px;
}

.input-group-w100 .input-group {
    width: 100%;
}

.cursor-pointer {
    cursor: pointer;
}

.tr-alert {
    background: #fff918 !important;
}

@media (min-width: 992px) {
    .modal-lg {
        width: 1100px;
    }
}
